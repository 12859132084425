<template>
  <div id="app" class="page-login-v3 layout-full">
    <div class="page vertical-align text-center">
      <div class="page-content vertical-align-middle">
        <div class="panel">
          <div class="panel-body">
            <div class="brand">
              <h2 class="brand-text font-size-18">Авторизация</h2>
            </div>
            <b-alert v-if="error" show dismissible variant="danger">{{ error }}</b-alert>
            <b-form @submit="login" autocomplete="off">
              <b-form-group class="form-material floating">
                <b-form-input type="email" name="email" v-model="form.login"></b-form-input>
                <label class="floating-label">Логин</label>
              </b-form-group>
              <b-form-group class="form-material floating">
                <b-form-input type="password" name="password" v-model="form.password"></b-form-input>
                <label class="floating-label">Пароль</label>
              </b-form-group>
              <b-button type="submit" class="btn-primary btn-block btn-lg mt-4">Войти</b-button>
              <div class="mt-15">
                <b-link>Забыли пароль?</b-link>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../assets/js/api'
  import store from '@/store'

  export default {
    name: 'Login',
    data: function () {
      return {
        error: null,
        form: {
          login: null,
          password: null,
        },
      }
    },
    methods: {
      login: function (e) {
        e.preventDefault()
        api.auth.login(this.form).then(
          user => {
            store.commit('setAuthorized', true)
            store.commit('setUser', user)
            this.$router.push('/')
          },
          error => {
            this.error = error
          }
        )
      },
    },
  }
</script>

<style lang="scss">
  .page-login-v3 {
    &:before {
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: '';
      background-image: url('../../static/img/login-backgroung.svg');
      background-image: -o-linear-gradient(top, #3949ab 0, #283593 100%);
      background-repeat: repeat-x;
      background-position: center top;
      -webkit-background-size: cover;
      background-size: cover;
    }
    .panel {
      width: 400px;
      margin-bottom: 45px;
      background: #fff;
      border-radius: 0.286rem;
      .panel-body {
        padding: 50px 40px 40px;
      }
      .brand-text {
        margin-top: 8px;
      }
    }
    form {
      margin: 45px 0 30px;
      a {
        margin-left: 20px;
      }
      .form-material.floating & + .page-login-v3 {
        form {
          .form-material.floating {
            margin-top: 30px;
          }
        }
      }
      .form-material {
        label {
          font-weight: 300;
          color: #a3afb7;
        }
      }
    }
  }

  @media (max-width: 479px) {
    .page-login-v3 {
      .page-content {
        padding: 30px 20px;
      }
      .panel {
        width: auto;
        padding: 10px;
        .panel-body {
          padding: 35px 25px 35px;
        }
      }
    }
  }
</style>
